import { createTheme } from "@material-ui/core/styles";
import { deepPurple, purple, orange } from "@material-ui/core/colors";

const theme = createTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        "&$active": {
          color: orange[400],
        },
      },
    },
  },
  palette: {
    primary: deepPurple,
    secondary: purple,
  },
  spacing: 0,
  typography: {
    fontSize: 12,
    fontFamily: `"Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
  },
});

export default theme;

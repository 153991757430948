import React, { useMemo, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import _ from "lodash";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
  Select,
  MenuItem,
  Switch,
  FormControl,
  InputLabel,
  Avatar,
} from "@material-ui/core";
import SelectDepartment from "../../components/SelectDepartment/SelectDepartment";
import { NOTIFICATION_FREQUENCY } from "../../utils/const";
import { permissionDictionary } from "../../utils/authorization";
import { UserContext } from "../../context/userContext";
import Icon from "@mdi/react";
import { mdiAccount } from "@mdi/js";
import styles from "./SystemPrompts.module.scss";
import { removeTypenameDeep } from "../../utils/misc";

const EditDialog = ({
  open,
  handleClose,
  systemprompt,
  form,
  resetForm,
  formErrors,
  handleChange,
  handleSubmit,
  handleCreateSystemPromptCancel,
}) => {
  const { user: currentUser } = useContext(UserContext);

  useEffect(() => {
    if (systemprompt) {
      const { id, organization, category, value, summary, author } = systemprompt;
      let editForm = {
        id,
        organization,
        category,
        value,
        summary,
        author
      };
      resetForm(editForm);
    }
  }, [systemprompt]);

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        onExited={() => resetForm()}
      >
        <DialogTitle>{systemprompt ? "Edit" : "Create"} System Prompt</DialogTitle>
        <DialogContent>
          <TextField
            label="Question or Prompt"
            name="value"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.value || ""}
            onChange={handleChange}
            helperText={formErrors.value}
            error={Boolean(formErrors.value)}
          />
          <TextField
            label="Description or Instructions (Optional)"
            name="summary"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.summary || ""}
            onChange={handleChange}
            helperText={formErrors.summary}
            error={Boolean(formErrors.summary)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={systemprompt ? handleClose : () => handleCreateSystemPromptCancel(form)} variant="outlined">
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmit} variant="contained" data-test='cud-button-save'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditDialog;

import React, { useState, useEffect, useRef, useContext } from "react";
import handleViewport from "react-in-viewport";
import styles from "./WeeklyTargets.module.scss";
import _ from "lodash";
import { useInViewport as useInView } from "react-in-viewport";
import { withStyles } from "@material-ui/styles";

import { TableRow, TableCell, MenuItem, TextField, Typography, IconButton, Tooltip } from "@material-ui/core";
import Icon from "@mdi/react";
import {
  mdiCodeGreaterThanOrEqual,
  mdiCodeLessThan,
  mdiEqualBox,
  mdiDockWindow,
  mdiCodeLessThanOrEqual,
  mdiCodeGreaterThan,
  mdiCodeBrackets,
  mdiArrowUpBold,
  mdiArrowDownBold,
  mdiEqual,
  mdiDrag,
  mdiPercent,
  mdiDelta,
} from "@mdi/js";

import { SnackbarContext } from "../../context/snackbarContext";
import { parseWTValueUsingFormula } from "../../utils/misc";
import { isSameWeek, isBefore, format, add, parse, startOfDay } from "date-fns";

import useInViewport from "../../hooks/useInViewport";
import useForm from "../../hooks/useForm";

import UserAvatars from "../UserAvatars/UserAvatars";
import NotesButton from "../Notes/NotesButton";
import Menu from "../Menu/Menu";
import Skeleton from "./Skeleton";
import PlanPill from "../PlanPill/PlanPill";
import NumberFormatCustom from "../NumberFormat/NumberFormat";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 13,
    padding: 0,
  },
}))(Tooltip);

const WeeklyTarget = ({
  innerRef,
  provided,
  inViewport,
  forwardedRef,
  weeks,
  today,
  handleAddTodoOrIssue,
  canEdit,
  handleEnlarge,
  handleAddToSavePending,
  saving,
  weeklyTarget,
  referenceMap,
  handleEditDialog,
  planId,
  updateWeeklyTarget,
  handleConfirmOpen,
  savePending,
  handleHide,
}) => {
  const {
    id,
    value,
    enableFormula,
    formula,
    formulaScope,
    number,
    accuracy,
    target,
    targetMin,
    targetMax,
    unit,
    measurables,
    user,
    plans,
    hide,
  } = weeklyTarget;

  const { snack } = useContext(SnackbarContext);

  const { removeFormattedNum, valueAsFormattedNum } = useForm({});
  const [values, setValues] = useState(
    measurables.reduce((obj, val, idx, array) => {
      const formattedWeek = format(add(new Date(val.week), { hours: 12 }), "yyyyMMdd");
      const formattedNotes = (val.notes || []).map((note) => _.pick(note, ["id"]));
      obj[formattedWeek] = { value: val.value, notes: formattedNotes, index: idx };
      return obj;
    }, {})
  );

  const hasRendered = useInViewport(inViewport);

  const handleChange = (e) => {
    // const regex = /^[+-]?([0-9]*([.][0-9]*)?|[.][0-9]+)$/;
    const { name, value } = e.target;
    // const val = removeFormattedNum(value);

    // if (val && !regex.test(val)) return;
    const newValues = { ...values };
    if (value === '') {
      delete newValues[name];
    } else {
      newValues[name] = { ...values[name], value };
    }


    setValues(newValues);

    const measureableInput = Object.keys(newValues).map((key) => {
      return { week: parse(key, "yyyyMMdd", startOfDay(new Date())), value: _.toNumber(newValues[key].value), notes: newValues[key].notes };
    });

    handleAddToSavePending(id, () => {
      return updateWeeklyTarget({ variables: { id, measurables: measureableInput } });
    });
  };

  const handleToggleHide = async () => {
    const ok = await handleHide({ variables: { id, hide: !hide } });

    if (ok.data.updateWeeklyTarget) {
      snack(`"${value}" marked as ${hide ? "visible" : "hidden"}`);
    }
  };

  // const handleUpdate = async () => {
  //   const measureableInput = Object.keys(values).map((key) => {
  //     return { week: parse(key, "yyyyMMdd", startOfDay(new Date())), value: _.toNumber(values[key].value), notes: values[key].notes };
  //   });

  //   handleAddToSavePending(id, () => {
  //     return updateWeeklyTarget({ variables: { id, measurables: measureableInput } });
  //   });
  // };

  const getUnit = () => {
    if (_.isNil(target) && _.isNil(targetMax) && _.isNil(targetMin)) return null;
    if (unit === "$") {
      if (accuracy !== "btw") {
        return `${unit}${Number(target).toLocaleString()}`;
      } else {
        return `${unit}${Number(targetMin).toLocaleString()} - ${Number(targetMax).toLocaleString()}`;
      }
    }
    if (accuracy !== "btw") {
      return `${Number(target).toLocaleString()}${unit ? ` ${unit}` : ""}`;
    } else {
      return `${Number(targetMin).toLocaleString()} - ${Number(targetMax).toLocaleString()}${unit ? ` ${unit}` : ""}`;
    }
  };

  // Update values when drag & dropped
  useEffect(() => {
    setValues(
      measurables.reduce((obj, val, idx) => {
        const formattedNotes = (val.notes || []).map((note) => note.id);
        obj[format(add(new Date(val.week), { hours: 12 }), "yyyyMMdd")] = { value: val.value, notes: formattedNotes, index: idx };
        return obj;
      }, {})
    );
  }, [measurables]);

  if (!hasRendered) {
    return (
      <TableRow
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={(el) => {
          forwardedRef.current = el;
          innerRef(el);
        }}
        className={styles.skeletonRow}
      >
        <Skeleton />
      </TableRow>
    );
  }

  const uniqPlans = _.uniqBy(plans, (plan) => plan.sharedPlanId);

  let totalWeekValue = 0;
  let countedWeeks = 0;

  return (
    <TableRow
      {...provided.draggableProps}
      ref={(el) => {
        forwardedRef.current = el;
        innerRef(el);
      }}
      style={{ ...provided.draggableProps.style }}
      className={`${styles.row} ${hide ? styles.hidden : ""}`}
    >
      <TableCell className={styles.fixed}>
        <div {...provided.dragHandleProps} style={{ opacity: canEdit ? 1 : 0.25, justifyContent: "center" }}>
          <Icon path={mdiDrag} size={1} />
        </div>
      </TableCell>
      <TableCell className={styles.fixed}>
        <UserAvatars users={user ? [user] : []} />
      </TableCell>
      <TableCell className={styles.fixed}>
        <div className={styles.flex}>
          {handleEnlarge && (
            <IconButton onClick={handleEnlarge(id)} size="small">
              <Icon path={mdiDockWindow} size={0.75} color="rgba(0, 0, 0, 0.54)" />
            </IconButton>
          )}
          <span className={styles.flex}>
            {uniqPlans.map((plan, idx) => {
              return <PlanPill plan={plan} key={idx} />;
            })}
            {_.isEmpty(uniqPlans) && <PlanPill plan={null} />}
            {value} {unit && ` (${unit})`}
          </span>
          <NotesButton
            id={id}
            model="weeklyTarget"
            value={value}
            user={_.get(user, "id")}
            doc={weeklyTarget}
            tabs={["notes", "issues", "todos"]}
            canEditTodo={canEdit}
            planId={planId}
            disabled={!canEdit}
          />
          {canEdit && (
            <Menu>
              {/* <MenuItem onClick={handleAddTodoOrIssue("todo", "WeeklyTarget", id)}>Add Todo</MenuItem>
                <MenuItem onClick={handleAddTodoOrIssue("issue", "WeeklyTarget", id, value, user.id)}>Add Issue</MenuItem> */}
              <MenuItem onClick={handleEditDialog(true, weeklyTarget)}>Edit</MenuItem>
              <MenuItem onClick={handleToggleHide}>{hide ? "Show" : "Hide"}</MenuItem>
              <MenuItem onClick={handleConfirmOpen(true, weeklyTarget)} className={styles.delete}>
                Delete
              </MenuItem>
            </Menu>
          )}
        </div>
      </TableCell>
      <TableCell className={styles.fixed}>
        <div className={styles.flex}>
          <Icon path={accuracyIcon[accuracy]} size={0.75} color="rgba(0, 0, 0, 0.54)" className={styles.iconAccuracy} />
          <Typography noWrap variant="inherit">
            {getUnit()}
          </Typography>
        </div>
      </TableCell>
      {calcWeeks({
        weeks,
        today,
        weeklyTarget,
        referenceMap,
        values,
        totalWeekValue,
        countedWeeks,
        valueAsFormattedNum,
      }).map((week) => {
        const {
          formattedWeek,
          isCurrent,
          isPast,
          valueForWeek,
          aboveTarget,
          averageAboveTarget,
          averageDisplayValue,
          notesFortheWeek,
          additionalProps,
          isLastWeek,
          changeOverLastWeek,
          percentChangeOverLastWeek,
        } = week;
        return (
          <React.Fragment key={`${id}-${formattedWeek}`}>
            <TableCell className={isCurrent ? styles.cellCurrent : isPast ? styles.cellPast : undefined}>
              <div className={styles.metricToggle}>
                <VirtualizedField
                  value={value}
                  valueForWeek={valueAsFormattedNum(valueForWeek)}
                  handleChange={handleChange}
                  formattedWeek={formattedWeek}
                  aboveTarget={aboveTarget}
                  id={id}
                  // handleUpdate={handleUpdate}
                  saving={saving}
                  enableFormula={enableFormula}
                  unit={unit}
                  user={user}
                  notesFortheWeek={notesFortheWeek}
                  additionalProps={additionalProps}
                  changeOverLastWeek={changeOverLastWeek}
                  percentChangeOverLastWeek={percentChangeOverLastWeek}
                  savePending={savePending}
                />
              </div>
            </TableCell>
            {isLastWeek && (
              <TableCell key={`${id}-average`} className={averageAboveTarget ? styles.above : styles.below}>
                <div className={styles.metricToggle}>
                  <Typography align="right" variant="body1" component="label" htmlFor={`${id}-${week}`} className={styles.averageText}>
                    {unit === "$" && averageDisplayValue ? "$" : ""}
                    {averageDisplayValue}
                  </Typography>
                </div>
              </TableCell>
            )}
          </React.Fragment>
        );
      })}
    </TableRow>
  );
};

export default handleViewport(WeeklyTarget);

const accuracyIcon = {
  lt: mdiCodeLessThan,
  lte: mdiCodeLessThanOrEqual,
  gt: mdiCodeGreaterThan,
  gte: mdiCodeGreaterThanOrEqual,
  eq: mdiEqualBox,
  btw: mdiCodeBrackets,
  percent: mdiPercent,
  delta: mdiDelta,
};

const checkIfAboveTarget = (accuracy, valueForWeekNum, target, targetMax, targetMin, previousWeekValue) => {
  let aboveTarget = false;
  switch (accuracy) {
    case "lt":
      aboveTarget = valueForWeekNum < parseFloat(target);
      break;
    case "eq":
      aboveTarget = valueForWeekNum === parseFloat(target);
      break;
    case "gt":
      aboveTarget = valueForWeekNum > parseFloat(target);
      break;
    case "gte":
      aboveTarget = valueForWeekNum >= parseFloat(target);
      break;
    case "lte":
      aboveTarget = valueForWeekNum <= parseFloat(target);
      break;
    case "btw":
      aboveTarget = valueForWeekNum <= parseFloat(targetMax) && valueForWeekNum >= parseFloat(targetMin);
      break;
    case "percent":
      aboveTarget =
        !_.isNil(previousWeekValue) && previousWeekValue !== "" && previousWeekValue !== 0
          ? (valueForWeekNum / previousWeekValue) * 100 - 100 >= parseFloat(target)
          : true;
      break;
    case "delta":
      aboveTarget =
        !_.isNil(previousWeekValue) && previousWeekValue !== "" ? valueForWeekNum - previousWeekValue >= parseFloat(target) : true;
      break;
    default:
      break;
  }
  return aboveTarget;
};

const calcWeeks = ({ weeks, today, weeklyTarget, referenceMap, values, totalWeekValue, countedWeeks, valueAsFormattedNum }) => {
  const { enableFormula, formula, formulaScope, accuracy, target, targetMin, targetMax, measurables } = weeklyTarget;
  let calculatedWeeks = [];
  weeks.forEach((week, i, array) => {
    const formattedWeek = format(add(new Date(week), { hours: 12 }), "yyyyMMdd");
    const isPast = isBefore(week, today);
    const isCurrent = isSameWeek(week, today);
    if (!isCurrent && !isPast) return null;
    const valueForWeek = enableFormula
      ? parseWTValueUsingFormula({ formula, formulaScope, formattedWeek, referenceMap })
      : _.get(values, `${formattedWeek}.value`);
    const notesIndex = _.get(values, `${formattedWeek}.index`);
    const notesPath = !_.isNil(notesIndex) ? `measurables.${notesIndex}` : `measurables.${measurables.length}`;
    const notesFortheWeek = _.get(values, `${formattedWeek}.notes`, []);
    const additionalProps = {
      week: parse(formattedWeek, "yyyyMMdd", startOfDay(new Date())),
      path: notesPath,
      new: !_.isNil(notesIndex) ? false : true,
    };
    const valueForWeekNum = parseFloat(valueForWeek);
    if (!isNaN(valueForWeekNum)) {
      totalWeekValue += valueForWeekNum;
      countedWeeks += 1;
    }
    // const displayValue = valueAsFormattedNum(valueForWeek);
    let valueFromLastWeek = _.get(calculatedWeeks, `${[calculatedWeeks.length - 1]}.valueForWeek`);
    const aboveTarget = checkIfAboveTarget(accuracy, valueForWeekNum, target, targetMax, targetMin, valueFromLastWeek);

    const isLastWeek = i === array.length - 1;
    let weeksAverage;
    let averageAboveTarget = false;
    let averageDisplayValue;
    if (isLastWeek) {
      weeksAverage = totalWeekValue / countedWeeks;
      averageAboveTarget = checkIfAboveTarget(accuracy, weeksAverage, target, targetMax, targetMin, valueFromLastWeek);
      averageDisplayValue = valueAsFormattedNum(_.round(weeksAverage, 2));
    }

    let changeOverLastWeek, percentChangeOverLastWeek;

    if (!_.isNil(valueFromLastWeek) && valueFromLastWeek !== "" && !_.isNil(valueForWeek) && valueForWeek !== "") {
      changeOverLastWeek = valueForWeek - valueFromLastWeek;
      if (valueFromLastWeek !== 0) {
        percentChangeOverLastWeek = changeOverLastWeek / valueFromLastWeek;
      }
    }

    calculatedWeeks.push({
      formattedWeek,
      isCurrent,
      isPast,
      valueForWeek,
      aboveTarget,
      averageAboveTarget,
      averageDisplayValue,
      notesFortheWeek,
      additionalProps,
      isLastWeek,
      changeOverLastWeek,
      percentChangeOverLastWeek,
    });
  });

  return calculatedWeeks;
};

function VirtualizedField({
  value,
  valueForWeek,
  handleChange,
  formattedWeek,
  aboveTarget,
  id,
  // handleUpdate,
  saving,
  enableFormula,
  unit,
  user,
  notesFortheWeek,
  additionalProps,
  changeOverLastWeek,
  percentChangeOverLastWeek,
  savePending,
}) {
  const ref = useRef();
  const { inViewport } = useInView(ref, undefined, undefined, { onEnterViewPort: () => {}, onLeaveViewPort: () => {} });

  const increase = !_.isNil(changeOverLastWeek) && changeOverLastWeek > 0;
  const decrease = !_.isNil(changeOverLastWeek) && changeOverLastWeek < 0;
  return (
    <div ref={ref}>
      {inViewport ? (
        <div>
          <CustomTooltip
            placement="top"
            disableHoverListener={_.isNil(changeOverLastWeek) && _.isNil(percentChangeOverLastWeek)}
            title={
              <div className={increase ? styles.tooltipStatsGreen : decrease ? styles.tooltipStatsRed : styles.tooltipStats}>
                <div>
                  {!_.isNil(changeOverLastWeek) && (
                    <div className={styles.flex}>
                      <span>Delta: {changeOverLastWeek} </span>
                      <Icon path={increase ? mdiArrowUpBold : decrease ? mdiArrowDownBold : mdiEqual} size={0.75} />
                    </div>
                  )}
                  {!_.isNil(percentChangeOverLastWeek) && (
                    <div className={styles.flex}>
                      <span>Percentage: {_.round(percentChangeOverLastWeek * 100, 1)} % </span>
                      <Icon path={increase ? mdiArrowUpBold : decrease ? mdiArrowDownBold : mdiEqual} size={0.75} />
                    </div>
                  )}
                </div>
              </div>
            }
          >
            <TextField
              value={valueForWeek}
              onChange={handleChange}
              name={formattedWeek.toString()}
              className={aboveTarget ? styles.valueAbove : styles.valueBelow}
              id={`${id}-${formattedWeek}`}
              // onBlur={handleUpdate}
              disabled={saving || enableFormula}
              autoComplete="off"
              InputProps={{
                inputComponent: NumberFormatCustom,
                disableUnderline: enableFormula === true,
              }}
              inputProps={{ prefix: unit === "$" ? "$" : "" }}
            />
          </CustomTooltip>
          <div className={styles.weeklyNotesBtn}>
            <NotesButton
              id={id}
              model="weeklyTarget"
              value={value}
              user={_.get(user, "id")}
              doc={{ notes: notesFortheWeek }}
              tabs={["notes"]}
              additionalProps={additionalProps}
              addWarning={savePending}
              disabled={saving}
            />
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

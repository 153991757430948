import React from 'react';
import { Typography, Box } from '@material-ui/core';

const MeetingStepContent = ({ step }) => {
  if (!step || !Array.isArray(step.content)) {
    return null;
  }

  return (
    <div>
      {step.content.map((content, index) => (
        <Box key={index} mb={2}>
          {content.label && (
            <Typography variant="h6" gutterBottom>
              {content.label}
            </Typography>
          )}
          {content.type === 'textBlock' && (
            <Typography variant="body1" paragraph>
              {content.text}
            </Typography>
          )}
          {content.type !== 'textBlock' && Array.isArray(content.referenceIds) && (
            <div>
              {content.referenceIds.map((reference, refIndex) => (
                <Box key={refIndex} ml={2}>
                  <Typography variant="body1">
                    {reference.value}
                  </Typography>
                  {reference.summary && (
                    <Typography variant="body2" color="textSecondary">
                      {reference.summary}
                    </Typography>
                  )}
                </Box>
              ))}
            </div>
          )}
        </Box>
      ))}
    </div>
  );
};

export default MeetingStepContent;
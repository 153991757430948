import React, { useContext, useEffect, useState } from "react";
import styles from "./Meetings.module.scss";
import _ from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import useCorpPlan from "../../hooks/useCorpPlan";
import { Button, CircularProgress, Container, Grid } from "@material-ui/core";
import { DialogContext } from "../../context/dialogContext";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import Loading from "../../components/Loading/Loading";
import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { isAuthed } from "../../utils/authorization";
import MeetingCard from "./MeetingCard";
import { GET_MEETINGS } from "../../utils/query";
import { MEETING_FIELDS } from "../../utils/fragments";
import { MEETINGS_SUBSCRIPTION } from "../../utils/subscriptions";

const Meetings = ({ user, params, org }) => {
  const { dialog, setDialog } = useContext(DialogContext);
  const { snack } = useContext(SnackbarContext);
  const isAdmin = isAuthed(user.user, "department admin");

  const { corpForSelectedYear } = useCorpPlan({ orgId: params.org, fiscalYear: org.fiscalYear });

  const { data, loading, subscribeToMore } = useQuery(GET_MEETINGS, {
    variables: {
      organization: params.org,
      sharedPlanId: user.departmentFilter.sharedPlanId,
      meetingUser: user.user.id,
    },
    fetchPolicy: "network-only",
  });

  useEffect(
    () =>
      subscribeToMore({
        document: MEETINGS_SUBSCRIPTION,
        variables: { meetingUser: user.user.id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const meeting = subscriptionData.data.meetingPayload.meetingMutated;
          const action = subscriptionData.data.meetingPayload.action;
          let newMeetings;
          switch (action) {
            case "update":
              newMeetings = prev.meetings.map((t) => (t.id === meeting.id ? meeting : t));
              break;
            case "delete":
              newMeetings = prev.meetings.filter((t) => t.id !== meeting.id);
              break;
            case "create":
              newMeetings = [...prev.meetings, meeting];
              break;
            default:
              break;
          }

          return Object.assign({}, prev, {
            meetings: newMeetings,
          });
        },
      }),
    []
  );

  const [deleteMeeting, { loading: deleteLoading }] = useMutation(DELETE_MEETING, {
    // update(cache, { data: { deleteMeeting } }) {
    //   const deleteMeetingId = cache.identify(deleteMeeting);
    //   cache.modify({
    //     fields: {
    //       meetings: (existingMeetings) => {
    //         return existingMeetings.filter((meetingRef) => {
    //           const meetingId = cache.identify(meetingRef);
    //           return meetingId !== deleteMeetingId;
    //         });
    //       },
    //     },
    //   });
    // },
  });

  const handleOpenDialog = () => {
    setDialog({
      ...dialog,
      addMeetingDialog: { open: true, planId: _.get(corpForSelectedYear, "id") }, // also need to pass the query variables if you want to update the cache after the mutation
    });
  };

  const handleDelete = (id) => async () => {
    const response = await deleteMeeting({
      variables: { id },
    });
    if (response.data.deleteMeeting) {
      snack(`Meeting deleted`);
      // requestFetch();
    } else {
      snack("Something went wrong, please try again later", "error");
    }
  };

  if (loading || !data) return <Loading />;

  const meetings = _.get(data, "meetings", []);

  return (
    <>
      <Container maxWidth={false}>
        <div style={{ display: "flex", borderBottom: "1px solid gray" }}>
          <Button
            startIcon={<Icon path={mdiPlus} size={1} color="#fff" />}
            className={styles.icon}
            onClick={handleOpenDialog}
            variant="contained"
            color="primary"
            disabled={!isAdmin}
          >
            New Meeting
          </Button>
        </div>
        {_.isEmpty(meetings) ? (
          <div>No meetings created</div>
        ) : (
          <Grid container spacing={3} style={{ marginTop: "16px" }}>
            {meetings.map((meeting, idx) => (
              <Grid item xs={12} sm={6} md={4} key={meeting.id}>
                <MeetingCard
                  meeting={meeting}
                  org={params.org}
                  handleDelete={handleDelete(meeting.id)}
                  isAdmin={isAdmin}
                  currentCorpPlan={corpForSelectedYear}
                />
              </Grid>
            ))}
            <Grid item xs={12}></Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default Meetings;

const DELETE_MEETING = gql`
  mutation Meetings_DeleteMeeting($id: ID!) {
    deleteMeeting(id: $id) {
      id
    }
  }
`;

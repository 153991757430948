import React from "react";
import styles from "../Meetings.module.scss";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MeetingStepContent from "../../../components/MeetingStepContent/MeetingStepContent";
import AttendeesStatus from "../../../components/AttendeesStatus/AttendeesStatus";

const MeetingCheckInScreen = ({ org, step, invited, attendees }) => {

  return (
    <div>
      <p style={{ fontSize: 24, fontWeight: "bold" }}>Welcome to the meeting!</p>
      <AttendeesStatus invited={invited} attendees={attendees}/>
      <MeetingStepContent step={step} />
    </div>
  );
};

export default MeetingCheckInScreen;

export const NOTIFICATION_FREQUENCY = ["daily", "weekly", "monthly", "quarterly", "yearly"];
export const isProd = process.env.REACT_APP_ENV === "PROD";

// **IMPORTANT: the keys need to be the camelCase version of their respective "value" fields
// The duration values are strings because InputStep in meetingSchema has duration as String to work with MUI textfields
// (gets converted to Number when saved in DB)


export const DEFAULT_MEETING_ACTIONS = {
  checkIn: {
    name: "Check-in",
    value: "check-in",
    duration: "5",
    steps: [],
    content: [ { label: null, text: null, type: "prompt", referenceIds: ["1f0cde5c-d4c8-4192-93c6-87da8473ee1a","4a8e717f-7d35-4a7c-b675-e1666fa97f7e"] } ],
  },
  scorecard: {
    name: "Scorecard",
    value: "scorecard",
    duration: "5",
    steps: [],
  },
  rocks: {
    name: "Rocks",
    value: "rocks",
    duration: "5",
    steps: [],
  },
  textBlock: {
    name: "Text Block",
    value: "text block",
    duration: "5",
    steps: [],
  },
  toDos: {
    name: "To-Dos",
    value: "to-dos",
    duration: "5",
    steps: [],
  },
  issues: {
    name: "Issues",
    value: "issues",
    duration: "5",
    steps: [],
  },
  conclude: {
    name: "Conclude",
    value: "conclude",
    duration: "5",
    steps: [],
  },
  prompt: {
    name: "Question/Prompt",
    value: "prompt",
    duration: "5",
    steps: [],
  }
};

export const REPEATABLE_MEETING_ACTIONS = ["textBlock"];

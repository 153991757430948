import React, { useEffect, useState } from "react";
import _ from "lodash";
// import { utcToZonedTime } from "date-fns-tz";
import { addToDate, formatAs } from "../../utils/dates";
import { getDaysInMonth, isToday, subDays } from "date-fns";
import styles from "./Calendar.module.scss";
import { useQuery } from "@apollo/client";
import { GET_MEETINGS } from "../../utils/query";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { IconButton } from "@material-ui/core";
import { MEETINGS_SUBSCRIPTION } from "../../utils/subscriptions";

const Calendar = ({ org, user }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const { startDate, endDate, rows } = getDateRangeForCalendarMonth(year, month);
  const history = useHistory();
  const { data, subscribeToMore } = useQuery(GET_MEETINGS, {
    variables: {
      organization: org.id,
      sharedPlanId: user.departmentFilter.sharedPlanId,
      meetingUser: user.user.id,
      startDate: startDate,
      endDate: endDate,
    },
  });
  const handleClickMeeting = (meetingId) => {
    history.push(`/${org.id}/meeting/${meetingId}`);
  };

  const handleChangeMonth = (increment) => {
    setMonth(month + increment);
  };

  useEffect(
    () =>
      subscribeToMore({
        document: MEETINGS_SUBSCRIPTION,
        variables: { meetingUser: user.user.id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const meeting = subscriptionData.data.meetingPayload.meetingMutated;
          const action = subscriptionData.data.meetingPayload.action;
          let newMeetings;
          switch (action) {
            case "update":
              newMeetings = prev.meetings.map((t) => (t.id === meeting.id ? meeting : t));
              break;
            case "delete":
              newMeetings = prev.meetings.filter((t) => t.id !== meeting.id);
              break;
            case "create":
              newMeetings = [...prev.meetings, meeting];
              break;
            default:
              break;
          }

          return Object.assign({}, prev, {
            meetings: newMeetings,
          });
        },
      }),
    []
  );

  let meetings = _.get(data, "meetings") || [];

  const meetingsByDate = _.groupBy(meetings, (meeting) => formatAs(meeting.startTime, "MMM dd, yyyy"));
  const datesForMonth = createDatesForMonth(startDate, meetingsByDate, rows);

  return (
    <div className={styles.scrollContainer}>
      <div className={styles.container}>
        <div className={styles.toolbar}>
          <IconButton onClick={() => handleChangeMonth(-1)} size="small">
            <Icon path={mdiChevronUp} size={1} color={"#000"} />
          </IconButton>
          <IconButton onClick={() => handleChangeMonth(1)} size="small">
            <Icon path={mdiChevronDown} size={1} color={"#000"} />
          </IconButton>
          <span className={styles.toolbarLabel}>{formatAs(new Date(year, month, 1), "MMMM yyyy")}</span>
        </div>
        <div className={styles.header}>
          <div>Sun</div>
          <div>Mon</div>
          <div>Tue</div>
          <div>Wed</div>
          <div>Thu</div>
          <div>Fri</div>
          <div>Sat</div>
        </div>
        <div className={styles.datesContainer}>
          {datesForMonth.map((d, i) => {
            const date = new Date(d.date).getDate();
            const meetings = d.meetings;
            const isCurrentDay = isToday(new Date(d.date));
            return (
              <div key={i} className={isCurrentDay ? styles.todayDate : styles.date}>
                <div className={styles.dateLabel}>{date === 1 ? formatAs(new Date(d.date), "MMM d") : date}</div>
                {meetings.map((meeting) => {
                  return (
                    <div key={meeting.id} className={styles.meetingLabel} onClick={() => handleClickMeeting(meeting.id)}>
                      {formatAs(meeting.startTime, "h:mm aa")} {meeting.title}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Calendar;

const getDateRangeForCalendarMonth = (year, month) => {
  // number of rows to show all days in the month
  let rows = 5;
  const startingDate = new Date(year, month, 1);

  const daysInMonth = getDaysInMonth(startingDate);
  const dayOftheStartingDate = startingDate.getDay();
  if (dayOftheStartingDate + daysInMonth > 35) {
    rows = 6;
  }
  // default calendar week start on Sundays
  const calendarStartDate = subDays(startingDate, dayOftheStartingDate);
  const calendarEndDate = addToDate(calendarStartDate, { days: rows * 7 });

  return { startDate: calendarStartDate, endDate: calendarEndDate, rows };
};

const createDatesForMonth = (calendarStartingDate, meetingsByDate, rows) => {
  let dates = [];
  for (let i = 0; i < rows * 7; i++) {
    const date = addToDate(calendarStartingDate, { days: i });
    const dateISOString = date.toISOString();
    const dateKey = formatAs(date, "MMM dd, yyyy");
    const dateMeetings = meetingsByDate[dateKey] || [];

    dates = [
      ...dates,
      {
        date: dateISOString,
        meetings: dateMeetings,
      },
    ];
  }

  return dates;
};

import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloClient, split, InMemoryCache, ApolloProvider, from, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "typeface-montserrat";

import App from "./App";
import { UserContextProvider } from "./context/userContext";
import { DialogContextProvider } from "./context/dialogContext";
import { FetchContextProvider } from "./context/fetchContext";
import { NotesContextProvider } from "./context/notesContext";
import { LoadingContextProvider } from "./context/loadingContext";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_SERVER_URI,
  credentials: "same-origin",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_SUBSCRIPTION_URI,
    connectionParams: { authToken: localStorage.getItem("token") },
    shouldRetry: () => true,
    retryAttempts: Infinity, // Retry indefinitely
    retryWait: async (retryCount) => {
      const delay = Math.min(1000 * 2 ** retryCount, 30000); // Exponential backoff with a max delay of 30s
      console.log(`Retrying in ${delay} ms (attempt ${retryCount + 1})`);
      await new Promise((resolve) => setTimeout(resolve, delay));
    },
    on: {
      connected: () => {
        console.log("WebSocket connected");
      },
      closed: () => {
        console.log("WebSocket closed, retrying...");
      },
      error: (error) => {
        console.error("WebSocket error", error);
      },
    },    
  })
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const removeTypenameLink = removeTypenameFromVariables();

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}`, { locations, path }));

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  from([errorLink, removeTypenameLink, wsLink]),
  from([authLink, errorLink, removeTypenameLink, httpLink])
);

// Connect to the Apollo Server back end
const client = new ApolloClient({
  connectToDevTools: false, //uncomment when you want to work with apollo dev tools
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          moreTodos: {
            keyArgs: ["organization", "sharedPlanId", "category", "searchTerm", "oneYearCorpPlan"],
            merge(existing = { todos: [] }, incoming) {
              return {
                todos: [...existing.todos, ...incoming.todos],
                nextCursor: incoming.nextCursor,
              };
            },
          },
        },
      },
    },
  }),
});

render(
  <Router>
    <ApolloProvider client={client}>
      <UserContextProvider>
        <DialogContextProvider>
          <FetchContextProvider>
            <LoadingContextProvider>
              <NotesContextProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <App />
                </MuiPickersUtilsProvider>
              </NotesContextProvider>
            </LoadingContextProvider>
          </FetchContextProvider>
        </DialogContextProvider>
      </UserContextProvider>
    </ApolloProvider>
  </Router>,
  document.getElementById("root")
);

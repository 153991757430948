import React from 'react';
import styles from "./AttendeesStatus.module.scss";

import { Typography, Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

const AttendeesStatus = ({ invited, attendees }) => {

  const waitingFor = invited.filter(invitedUser =>
    !attendees.some(attendee => attendee.id === invitedUser.id)
  );

  return (
    <Box>
      <Typography variant="h6">Waiting For</Typography>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {waitingFor && waitingFor.map((user, i) => (
          <Box key={`waiting-${i}`} className={styles.userContainer} display="flex" flexDirection="column" alignItems="center" mr={2} mb={2}>
            <Avatar className={styles.avatar} src={user.profilePicture}>
              {user.name.first[0] || ""}
            </Avatar>
            <Typography variant="body2" style={{ marginTop: 4, textAlign: 'center' }}>
              {user.name.first} {user.name.last}
            </Typography>
          </Box>
        ))}
      </Box>
          
      <Typography variant="h6">Present</Typography>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {attendees && attendees.map((user, i) => (
          <Box key={`attendee-${i}`} className={styles.userContainer} display="flex" flexDirection="column" alignItems="center" mr={2} mb={2}>
            <Avatar className={styles.avatar} src={user.profilePicture}>
              {user.name.first[0] || ""}
            </Avatar>
            <Typography variant="body2" style={{ marginTop: 4, textAlign: 'center' }}>
              {user.name.first} {user.name.last}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AttendeesStatus;
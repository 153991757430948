import gql from "graphql-tag";
import { MEETING_FIELDS } from "./fragments";

export const MEETINGS_SUBSCRIPTION = gql`
  ${MEETING_FIELDS}
  subscription OnMeetingMutation($meetingUser: ID!, $startDate: String, $endDate: String) {
    meetingPayload(meetingUser: $meetingUser, startDate: $startDate, endDate: $endDate) {
      meetingMutated {
        ...MeetingFields
      }
      action
    }
  }
`;

import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { TODO_FIELDS } from "../../../utils/fragments";
import Loading from "../../../components/Loading/Loading";
import useCorpPlan from "../../../hooks/useCorpPlan";
import _ from "lodash";
import TodosComponent from "../../../components/Todos/Todos";
import useTableFilters from "../../../hooks/useTableFilters";

const TodoStep = ({ userIds, org, canEdit, category }) => {
  const variables = { users: userIds, organization: org.id, category };

  const { subscribeToMore, data, loading } = useQuery(TODO_STEP_QUERY, { variables });
  const { corpForSelectedYear } = useCorpPlan({ fiscalYear: org.fiscalYear, orgId: org.id });

  const location = category === "issue" ? "issuesPage" : "todosPage";
  const sortValue = JSON.parse(localStorage.getItem(`${location}.sortValue`)) || "priorityValue";
  const sortOrder = JSON.parse(localStorage.getItem(`${location}.sortOrder`)) || "asc";
  const sort = { value: sortValue, order: sortOrder };
  const rowsPerPage = JSON.parse(localStorage.getItem(`${location}.rowsPerPage`)) || 10;

  /*
  const {
    page,
    rowsPerPage,
    sort,
    searchTerm,
    debouncedSearchTerm,
    showCompleted,
    handleChangePage,
    handleRowsPerPage,
    setSort,
    setSearchTerm,
    setShowCompleted,
  } = useTableFilters({
    location,
    initialValue: {
      rowsPerPage: JSON.parse(localStorage.getItem(`${location}.rowsPerPage`)) || 10,
      showCompleted: JSON.parse(localStorage.getItem(`${location}.showCompleted`)) || false,
      sortValue: JSON.parse(localStorage.getItem(`${location}.sortValue`)) || "priorityValue",
      sortOrder: JSON.parse(localStorage.getItem(`${location}.sortOrder`)) || "asc",
    },
  });
  */
  useEffect(
    () =>
      subscribeToMore({
        document: TODO_STEP_SUBSCRIPTION,
        variables: { users: userIds, category },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const todo = subscriptionData.data.todoPayload.todoMutated;
          const action = subscriptionData.data.todoPayload.action;
          let newTodos;
          switch (action) {
            case "update":
              newTodos = prev.todos.map((t) => (t.id === todo.id ? todo : t));
              break;
            case "delete":
              newTodos = prev.todos.filter((t) => t.id !== todo.id);
              break;
            case "create":
              newTodos = [...prev.todos, todo];
              break;
            default:
              break;
          }

          return Object.assign({}, prev, {
            todos: newTodos,
          });
        },
      }),
    []
  );

  if (loading) return <Loading />;

  return (
    <div>
      <TodosComponent todos={data.todos} issues={category === "issue"} showAccountable planId={corpForSelectedYear.id} canEdit={canEdit} sort={sort} rowsPerPage={rowsPerPage}/>
      {/*<TodosComponent
            todos={data.todos}
            issues={category === "issue"}
            showAccountable
            handleChangePage={handleChangePage}
            handleRowsPerPage={handleRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            sort={sort}
            searchTerm={searchTerm}
            setSort={setSort}
            setSearchTerm={setSearchTerm}
            setShowCompleted={setShowCompleted}
            showCompleted={showCompleted}
            location={location}
            //total={todosCount.total}
            //totalCompleted={todosCount.totalCompleted}
            planId={corpForSelectedYear.id}
            canEdit={canEdit}
          />      */}
    </div>
  );
};

export default TodoStep;


/*
const GET_TODOS_AND_PLANS = gql`
  ${TODO_FIELDS}
  query Todos_GetTodosPlans(
    $organization: ID!
    $sharedPlanId: ID
    $category: String!
    $oneYearCorpPlan: ID
    $page: Int
    $rowsPerPage: Int
    $searchTerm: String
    $sortBy: String
    $sortDir: String
    $done: Boolean
  ) {
    todos(
      organization: $organization
      category: $category
      sharedPlanId: $sharedPlanId
      oneYearCorpPlan: $oneYearCorpPlan
      page: $page
      rowsPerPage: $rowsPerPage
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortDir: $sortDir
      done: $done
    ) {
      ...TodoFields
    }

    todosCount(
      organization: $organization
      category: $category
      sharedPlanId: $sharedPlanId
      oneYearCorpPlan: $oneYearCorpPlan
      searchTerm: $searchTerm
    ) {
      total
      totalCompleted
    }

  }
`;
*/

/*    $sharedPlanId: ID
    $oneYearCorpPlan: ID
    $page: Int
    $rowsPerPage: Int
    $searchTerm: String
    $sortBy: String
    $sortDir: String  */
const TODO_STEP_QUERY = gql`
  ${TODO_FIELDS}
  query TodoStepQuery(
    $organization: ID! 
    $users: [ID!] 
    $category: String
  ) {
    todos(
      organization: $organization, users: $users, category: $category
    ) {
      ...TodoFields
    }

    todosCount(
      organization: $organization
      users: $users 
      category: $category
    ) {
      total
      totalCompleted
    }    
  }
`;

const TODO_STEP_SUBSCRIPTION = gql`
  ${TODO_FIELDS}
  subscription OnTodoMutation($users: [ID!], $category: String) {
    todoPayload(users: $users, category: $category) {
      todoMutated {
        ...TodoFields
      }
      action
    }
  }
`;

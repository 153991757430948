import {
  add,
  addQuarters,
  addYears,
  format,
  isDate,
  isBefore,
  isAfter,
  sub,
  differenceInDays,
  differenceInCalendarDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInQuarters,
  getMilliseconds,
  getSeconds,
  getMinutes,
  getHours,
  startOfDay,
  endOfDay,
  getDay as getDayDf,
  getDate,
  getMonth,
  startOfMonth as startOfMonthDf,
  getYear as getYearDf,
  setYear as setYearDf,
  startOfWeek as startOfWeekDf,
  endOfWeek as endOfWeekDf,
  differenceInYears,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

export const fiscalYearStart = (fiscalYearEnd) => {
  if (isDate(fiscalYearEnd)) {
    return sub(fiscalYearEnd, { years: 1, days: -1 });
  } else {
    return sub(parseInt(fiscalYearEnd), { years: 1, days: -1 });
  }
};

export const fullDate = (date, num = 0) => {
  if (isDate(date)) {
    return format(addYears(date, num), "MMM dd, yyyy");
  } else {
    return format(addYears(parseInt(date), num), "MMM dd, yyyy");
  }
};

export const year = (date, num = 0) => {
  if (isDate(date)) {
    return format(addYears(date, num), "yyyy");
  } else {
    return format(addYears(parseInt(date), num), "yyyy");
  }
};

export const startOfMonth = (date) => {
  if (isDate(date)) {
    return startOfMonthDf(date);
  } else {
    return startOfMonthDf(parseInt(date));
  }
};

export const setYear = (date, year) => {
  if (isDate(date)) {
    return setYearDf(date, year);
  } else {
    return setYearDf(parseInt(date), year);
  }
};

export const getYear = (date) => {
  if (isDate(date)) {
    return getYearDf(date);
  } else {
    return getYearDf(parseInt(date));
  }
};

export const getDay = (date) => {
  if (isDate(date)) {
    return getDayDf(date);
  } else {
    return getDayDf(parseInt(date));
  }
};

export const month = (date) => {
  if (isDate(date)) {
    return format(date, "MMM");
  } else {
    return format(parseInt(date), "MMM");
  }
};

export const quarterDates = (date, quarter) => {
  if (isDate(date)) {
    return [startOfDay(addQuarters(date, quarter - 1)), endOfDay(sub(addQuarters(date, quarter), { days: 1 }))];
  } else {
    return [startOfDay(addQuarters(parseInt(date), quarter - 1)), endOfDay(sub(addQuarters(parseInt(date), quarter), { days: 1 }))];
  }
};

export const addToDate = (date, duration) => {
  if (isDate(date)) {
    return add(date, duration);
  } else {
    return add(parseInt(date), duration);
  }
};

export const subToDate = (date, duration) => {
  if (isDate(date)) {
    return sub(date, duration);
  } else {
    return sub(parseInt(date), duration);
  }
};

export const formatAs = (date, str) => {
  if (isDate(date)) {
    return format(date, str);
  } else {
    return format(parseInt(date), str);
  }
};

export const isBetween = (date, startDate, endDate) => {
  if (isDate(date)) {
    return isAfter(date, startDate) && isBefore(date, endDate);
  } else {
    return isAfter(parseInt(date), startDate) && isBefore(parseInt(date), endDate);
  }
};

export const isBetweenIgnoreYear = (date, startDate, endDate) => {
  if (isDate(date)) {
    return (
      isAfter(setYear(startOfDay(date), getYear(startDate)), startOfDay(startDate)) &&
      isBefore(setYear(startOfDay(date), getYear(startDate)), endOfDay(endDate))
    );
  } else {
    return (
      isAfter(setYear(startOfDay(parseInt(date)), getYear(startDate)), startOfDay(startDate)) &&
      isBefore(setYear(startOfDay(parseInt(date)), getYear(startDate)), endOfDay(endDate))
    );
  }
};

export const weeksPrior = (date, weeks) => {
  if (isDate(date)) {
    return Math.abs(differenceInWeeks(date, sub(new Date(), { weeks }))) > weeks;
  } else {
    return Math.abs(differenceInWeeks(parseInt(date), sub(new Date(), { weeks }))) > weeks;
  }
};

export const startOfWeek = (date) => {
  if (isDate(date)) {
    return startOfWeekDf(date, { weekStartsOn: 1 });
  } else {
    return startOfWeekDf(parseInt(date), { weekStartsOn: 1 });
  }
};

export const endOfWeek = (date) => {
  if (isDate(date)) {
    return endOfWeekDf(date, { weekStartsOn: 1 });
  } else {
    return endOfWeekDf(parseInt(date), { weekStartsOn: 1 });
  }
};

export const before = (date, dateTwo) => {
  if (isDate(date)) {
    return isBefore(date, dateTwo);
  } else {
    return isBefore(parseInt(date), dateTwo);
  }
};

export const after = (date, dateTwo) => {
  if (isDate(date)) {
    return isAfter(date, dateTwo);
  } else {
    return isAfter(parseInt(date), dateTwo);
  }
};

export const beforeIgnoreYear = (date, dateTwo) => {
  if (isDate(date)) {
    return isBefore(date, setYear(startOfDay(dateTwo), getYear(date)));
  } else {
    return isBefore(parseInt(date), setYear(startOfDay(dateTwo), getYear(parseInt(date))));
  }
};

export const diffInDays = (date, dateTwo) => {
  if (isDate(date)) {
    return differenceInDays(startOfDay(date), startOfDay(dateTwo));
  } else {
    return differenceInDays(startOfDay(parseInt(date)), startOfDay(dateTwo));
  }
};

export const getCurrentQuarter = (planYear) => {
  if (!isDate(planYear)) {
    planYear = parseInt(planYear);
  }

  let fiscalStart = fiscalYearStart(planYear);
  let today = new Date();

  const isBeforeYearSpan = isBefore(today, fiscalStart);
  const isAfterYearSpan = isAfter(today, new Date(planYear));

  if (isAfterYearSpan) {
    return 4;
  }

  if (isBeforeYearSpan) {
    return 1;
  }

  let quarterDiff = differenceInQuarters(today, fiscalStart);
  let quarterIdx = quarterDiff % 4;

  let quarterNum = quarterIdx + 1;
  // if (fiscalStart > today) {
  //   /* if the fiscal start date is later than the current date, then the above difference and modulo calculations will be returning negative values
  //   wherein a -1 modulo means we are in Q4, -2 modulo means Q3, -3 modulo means Q2, and 0 modulo means Q1 hence the below statement */
  //   quarterNum = quarterIdx === 0 ? 1 : quarterIdx + 5;
  // }

  return quarterNum;
};

export const bhagTimeRemaining = (targetDate) => {
  if (targetDate) {
    const today = new Date(),
      target = new Date(parseInt(targetDate));

    const monthCount = differenceInMonths(target, today);

    const dayCountFrom = add(today, { months: monthCount });

    const dayNum = differenceInCalendarDays(target, dayCountFrom);

    const monthNum = monthCount % 12;

    const yearNum = Math.trunc(monthCount / 12);

    let timeStr = "";

    if (yearNum > 0) {
      if (yearNum === 1) {
        timeStr += `${yearNum} Year `;
      } else {
        timeStr += `${yearNum} Years `;
      }
    }

    if (monthNum > 0) {
      if (monthNum === 1) {
        timeStr += `${monthNum} Month `;
      } else {
        timeStr += `${monthNum} Months `;
      }
    }

    if (dayNum > 0) {
      if (dayNum === 1) {
        timeStr += `${dayNum} Day`;
      } else {
        timeStr += `${dayNum} Days`;
      }
    }

    return timeStr;
  }
};

export const getNextPlanFiscalYear = (fiscalYear) => {
  // generate new fiscalYear based on current date
  if (!isDate(fiscalYear)) {
    fiscalYear = parseInt(fiscalYear);
  }

  const currentDateTimeStart = new Date();
  const currentDateTimeEnd = addYears(currentDateTimeStart, 1);

  const endDateForNextPlan = addYears(fiscalYear, 1);
  let newPlanYear;
  if (isBefore(currentDateTimeEnd, endDateForNextPlan)) {
    newPlanYear = endDateForNextPlan;
  } else {
    const fullYearDiff = differenceInYears(currentDateTimeEnd, endDateForNextPlan);
    newPlanYear = addYears(endDateForNextPlan, fullYearDiff);
  }
  return newPlanYear;
};

export const mergeDateAndTime = (datePart, timePart, timezone) => {
  // Extract date components
  const year = getYearDf(datePart);
  const month = getMonth(datePart);
  const day = getDate(datePart);

  const dateStr = format(datePart, "yyyy-MM-dd");

  // Extract time components
  const hours = getHours(timePart);
  const minutes = getMinutes(timePart);
  const seconds = getSeconds(timePart);
  const milliseconds = getMilliseconds(timePart);

  const timeStr = format(timePart, "kk:mm:ss.SSS");

  return zonedTimeToUtc(`${dateStr} ${timeStr}`, timezone);
};

export const getQuarterProgress = (planYearEnd) => {
  if (!isDate(planYearEnd)) {
    planYearEnd = parseInt(planYearEnd);
  }

  const today = new Date();
  const fiscalStart = sub(planYearEnd, { years: 1, days: -1 });

  let quarterDiff = differenceInQuarters(today, fiscalStart); // whole quarter difference, rounds down if less than a quarter
  if (quarterDiff < 0) {
    quarterDiff = 0;
  } else if (quarterDiff > 3) {
    quarterDiff = 3;
  }

  const quarterStartDate = addQuarters(fiscalStart, quarterDiff);
  const quarterEndDate = addQuarters(quarterStartDate, 1);

  const quarterTotalDays = differenceInDays(quarterEndDate, quarterStartDate);
  let quarterRemainingDays = differenceInDays(quarterEndDate, today);
  if (quarterRemainingDays > quarterTotalDays) {
    quarterRemainingDays = quarterTotalDays;
  } else if (quarterRemainingDays < 0) {
    quarterRemainingDays = 0;
  }

  return {
    totalDays: quarterTotalDays,
    remainingDays: quarterRemainingDays,
    quarterStartDate,
    quarterEndDate,
    quarterIndex: quarterDiff + 1,
  };
};

export const getPlanQuarterProgress = (planEndDate, planLengthYears) => {
  if (!isDate(planEndDate)) {
    planEndDate = parseInt(planEndDate);
  }

  const today = new Date();
  const planStartDate = sub(planEndDate, { years: planLengthYears, days: -1 });

  let totalQuarters = differenceInQuarters(planEndDate, planStartDate) + 1; //total quarters in plan, rounds down if less than a quarter, so add 1.
  let currentQuarter = differenceInQuarters(today, planStartDate) + 1;

  /*
  let quarterDiff = differenceInQuarters(today, planStartDate); // whole quarter difference, rounds down if less than a quarter
  if (quarterDiff < 0) {
    quarterDiff = 0;
  } else if (quarterDiff > 3) {
    quarterDiff = 3;
  }*/


  return {
    totalQuarters,
    currentQuarter,
    planStartDate
  };
};
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useMeeting from "./useMeeting";
import { Container, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Tooltip } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import styles from "./Meetings.module.scss";
import ScorecardStep from "./Steps/ScorecardStep";
import RockStep from "./Steps/RockStep";
import TodoStep from "./Steps/TodoStep";
import UserAvatars from "../../components/UserAvatars/UserAvatars";
import Icon from "@mdi/react";
import {
  mdiAccountGroup,
  mdiAccountMultiple,
  mdiAlertDecagram,
  mdiBullseyeArrow,
  mdiCheckCircle,
  mdiCheckboxMarked,
  mdiCrown,
  mdiHumanHandsup,
  mdiPhoneHangup,
  mdiRhombus,
  mdiText,
} from "@mdi/js";
import { green, grey, purple } from "@material-ui/core/colors";
import Menu from "../../components/Menu/Menu";
import MeetingTimer from "./MeetingTimer";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MeetingEndScreen from "./Steps/MeetingEndScreen";
import MeetingCheckInScreen from "./Steps/MeetingCheckInScreen";
import StepTimeIndicator from "./StepTimeIndicator";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    marginTop: "60px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listItemIcon: {
    minWidth: "40px",
  },
}));

const Meeting = ({ user, org }) => {
  const classes = useStyles();
  const theme = useTheme();
  const params = useParams();

  const [open, setOpen] = useState(false);

  const handleToggleDrawerOpen = () => {
    setOpen(!open);
  };
  const {
    loading,
    userLoading,
    subloading,
    isAdmin,
    isTempAdmin,
    data,
    currentStepIndex,
    completedStepIndex,
    currentSessionSubscription,
    handleChangeStep,
    handleStepButton,
    handleFinishMeeting,
    handlePassAdminControl,
    handleRevokeAdminControl,
  } = useMeeting(user);

  if (loading || userLoading || subloading) return null;
  if (!data) return null;
  const { title, steps, owner, users, endTime } = data.meeting;
  const sessionEndTime = _.get(currentSessionSubscription, "meetingSub.session.sessionEndTime");
  const meetingAlreadyEnded = !_.isNil(sessionEndTime) || !_.isNil(endTime);

  const allInvitedUsers = [...users, owner];
  const userById = _.keyBy(allInvitedUsers, "id");
 
  const userIds = Object.keys(userById);

  const attendees = (_.get(currentSessionSubscription, "meetingSub.session.users") || []).map((userId) => userById[userId]);
  const meetingUsers = _.get(data, "meeting.users") || [];
  const tempAdmin = _.get(currentSessionSubscription, "meetingSub.session.temporaryAdmin");
  const stepTime = _.get(currentSessionSubscription, "meetingSub.session.stepTime");
  const currentStep = steps[currentStepIndex];

  const stepsComponents = {
    "check-in": <MeetingCheckInScreen org={org} step={currentStep} invited={allInvitedUsers} attendees={attendees} />,
    scorecard: <ScorecardStep userIds={userIds} org={org} canEdit={isAdmin || isTempAdmin} />,
    rocks: <RockStep userIds={userIds} org={org} canEdit={isAdmin || isTempAdmin} />,
    "to-dos": <TodoStep userIds={userIds} org={org} category={"todo"} canEdit={isAdmin || isTempAdmin} />,
    issues: <TodoStep userIds={userIds} org={org} category={"issue"} canEdit={isAdmin || isTempAdmin} />,
    "end-screen": <MeetingEndScreen org={org} />,
  };


  return (
    <div className={classes.root}>
      <div className={styles.stepContainer}>
        <div className={styles.meetingTopBar}>
          <div className={styles.userContainer}>
            <Icon path={mdiAccountMultiple} size={1} color={grey[800]} style={{ marginRight: "4px" }} />
            {!_.isEmpty(_.get(currentSessionSubscription, "meetingSub.session.users", [])) && (
              <UserAvatars small users={attendees} hidden maxUsersVisible={5} />
            )}
          </div>
          <div>
            {isAdmin && (
              <Tooltip title="Update Meeting Control">
                <div>
                  <Menu icon="crown">
                    {tempAdmin && <MenuItem onClick={handleRevokeAdminControl}>Revote Admin Control</MenuItem>}
                    {!_.isEmpty(meetingUsers) ? (
                      meetingUsers.map((user) => (
                        <MenuItem key={user.id} onClick={() => handlePassAdminControl(user.id)}>
                          {user.name.first} {user.name.last}{" "}
                          {tempAdmin === user.id && <Icon path={mdiCrown} size={0.75} color="rgba(0, 0, 0, 0.54)" />}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No users</MenuItem>
                    )}
                  </Menu>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        <div className={styles.stepContent}>
          {!_.isNil(currentStep) && stepsComponents[currentStep.value] && !meetingAlreadyEnded && (
            <Container className={styles.container} maxWidth={false}>
              {stepsComponents[currentStep.value]}
            </Container>
          )}
          {meetingAlreadyEnded && (
            <Container className={styles.container} maxWidth={false}>
              {stepsComponents["end-screen"]}
            </Container>
          )}
        </div>
      </div>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        anchor="right"
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleToggleDrawerOpen}>{open ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
          <MeetingTimer currentSessionSubscription={currentSessionSubscription} />
        </div>
        <Divider />
        <List className={styles.menulist}>
          {steps.map(({ name, value, duration }, i) => {
            const isCurrentStep = i === currentStepIndex;
            const isReviewed = completedStepIndex > i;
            const stepStartTime = _.get(stepTime, `${i}.stepStartTime`);
            const timeSpentInSeconds = _.get(stepTime, `${i}.timeSpentInSeconds`) || 0;
            // const isLastStep = i === steps.length - 1;
            return (
              <ListItem
                key={i}
                className={styles.stepItem}
                button
                disabled={subloading || !(isAdmin || isTempAdmin)}
                onClick={handleChangeStep(i)}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <Icon
                    path={stepIcons[value]}
                    size={1}
                    color={isCurrentStep ? purple[400] : isReviewed ? green[400] : grey[800]}
                    className={styles.cardIcon}
                  />
                </ListItemIcon>
                <ListItemText>
                  <span className={styles.flex}>
                    {name}
                    {isReviewed && <Icon path={mdiCheckCircle} color={green[400]} size={0.75} className={styles.check} />}
                  </span>
                </ListItemText>
                {isCurrentStep && (
                  <StepTimeIndicator stepStartTime={stepStartTime} timeSpentInSeconds={timeSpentInSeconds} plannedDuration={duration} />
                )}
                {/* <ListItemSecondaryAction className={isCurrentStep ? undefined : styles.label}>{duration}m</ListItemSecondaryAction> */}
              </ListItem>
            );
          })}
        </List>
        <Divider />
        <List disablePadding>
          <ListItem
            className={styles.labelEndMeeting}
            button
            disabled={subloading || !(isAdmin || isTempAdmin) || meetingAlreadyEnded}
            onClick={handleFinishMeeting}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Icon path={mdiPhoneHangup} size={1} color={"#fff"} className={styles.cardIcon} />
            </ListItemIcon>
            <ListItemText>
              <span className={styles.flex}>{meetingAlreadyEnded ? "Meeting Ended" : "End Meeting"}</span>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

export default Meeting;

const stepIcons = {
  "check-in": mdiAccountGroup,
  scorecard: mdiBullseyeArrow,
  rocks: mdiRhombus,
  issues: mdiAlertDecagram,
  "to-dos": mdiCheckboxMarked,
  "text block": mdiText,
  conclude: mdiHumanHandsup,
};
